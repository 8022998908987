import pricepredictionData from "data/price_pridection_table.json";

//Constant for costDriver Analysis Page
const cD = pricepredictionData.data.Sheet.map((data) => ({
  name: data.material_name,
  code: data.material_name,
}));
export const costDrivers = [...new Map(cD.map((item) => [item["name"], item])).values()];
export const sourceOption = [
  {
    name: "ICIS",
    code: "345",
  },
  // {
  //   name: "IHS",
  //   code: "123",
  // },
];
//Constant for AppTopbar  Page
export const items = [
  {
    index: 1,
    label: "Profile",
    icon: "pi pi-user",
  },

  {
    index: 2,
    label: "Add User",
    icon: "pi pi-user-plus",
  },

  {
    index: 3,
    label: "Edit User",
    icon: "pi pi-user-edit",
  },
  {
    index: 4,
    label: "Logout",
    icon: "pi pi-sign-out",
    command: () => {
      localStorage.clear();
      window.location = "/login";
    },
  },
];
export const menuItems = [
  { label: "Material Overview", icon: "pi pi-fw pi-home", to: "/MaterialOverview" },
  { label: "Demand Prediction", icon: "pi pi-fw pi-pencil", to: "/Materialdatachart" },
  { label: "Cost Drivers Analysis", icon: "pi pi-fw pi-calendar", to: "/CostDriversAnalysis" },
  { label: "Supplier Analysis", icon: "pi pi-fw pi-calendar", to: "/SupplierAnalysis" },
];
//Constant for AppMenu  Page
export const city = [
  { name: "600234" },
  { name: "678456" },
  { name: "700047" },
  { name: "768971" },
  { name: "789045" },
];
export const plant = [{ name: "2000" }, { name: "2100" }, { name: "3000" }, { name: "3100" }, { name: "4000" }];
//constant for supplier
export const supplierFormulaData = [
  {
    supplier_name: "A",
    formulae: "1.15 * [Polyethylene (Africa)-LLDPE Bulk Africa E Weekly] + 110",
    capacity: "1000",
    lead_time_months: "1",
  },
  {
    supplier_name: "B",

    formulae: "1.18 * [Polypropylene (US)-Homopolymer Bulk US Monthly] + 100",
    capacity: "980",
    lead_time_months: "2",
  },
  {
    supplier_name: "C",
    formulae: "1.20 * [Polypropylene (Middle East)-Film Posted Bulk China Weekly] + 120",
    capacity: "1200",
    lead_time_months: "3",
  },
];
//constant for Home page buttons
export const lendingPageMenuItems = [
  {
    label: "Order Optimization",
    to: "/orderOptimization/MaterialOverview",
  },
  {
    label: "Demand Analysis",
    to: "/demandPrediction",
  },
  {
    label: "Cost Drivers Analysis",
    to: "/CostDriversAnalysis",
  },
];
//headerconstant for materialdatachart
export const headerMaterial = (
  <div className="table-header-container">
    <h5
      style={{
        fontWeight: "bolder",
        fontFamily: "poppins",
        display: "flex",
        justifyContent: "left",
      }}
    >
      Material
    </h5>
  </div>
);
export const headerInventory = (
  <div className="table-header-container">
    <h5 style={{ fontWeight: "bolder", fontFamily: "poppins" }}>Inventory</h5>
    <h6 style={{ fontWeight: "lighter", fontFamily: "poppins" }}>Quantities are in Tonnes</h6>
  </div>
);
export const headerInventoryStatus = (
  <div className="table-header-container">
    <h5 style={{ fontWeight: "bolder", fontFamily: "poppins" }}>Inventory Status In Future (Without Buyer Action)</h5>
    <h6 style={{ fontWeight: "lighter", fontFamily: "poppins" }}> All values are in Tonnes</h6>
  </div>
);
//headerconstant for oderingschedule
export const headerOrderingSchedule = (
  <div className="table-header-container">
    <h5 style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>Ordering Schedule</h5>
    <h6 style={{ fontWeight: "lighter", fontFamily: "Poppins" }}>All values are in Tonnes</h6>
  </div>
);
//headerconstant for inventory
// export const headerPlant = (
//   <div className="table-header-container">
//     <h5 style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>Plant- {localStorage.getItem("plant")}</h5>
//     <h6 style={{ fontWeight: "lighter", fontFamily: "Poppins" }}>All values are in Tonnes</h6>
//   </div>
// );
export const headerForecastedPrices = (
  <div className="table-header-container">
    <h5 style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>Forecasted Prices</h5>
    <h6 style={{ fontWeight: "lighter", fontFamily: "Poppins" }}>All prices are in US$/Tonne</h6>
  </div>
);
//headerconstant for coastdriveranalysis
export const headerModelAccuracies = (
  <div className="table-header-container">
    <h5 style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>Model Accuracies</h5>
  </div>
);
//headerconstant supplieranalysis
export const headersupplieranalysis = (
  <div className="table-header-container">
    <h5
      style={{
        fontWeight: "bolder",
        fontFamily: "Poppins",
        display: "flex",
        justifyContent: "left",
      }}
    >
      Enter Supplier Information{" "}
    </h5>
  </div>
);
//headerconstant for materialoverview
export const headerMaterialOverview = (
  <div className="table-header-container">
    <h5
      style={{
        fontWeight: "bolder",
        fontFamily: "Poppins",
        display: "flex",
        justifyContent: "center",
      }}
    >
      Material Overview
    </h5>
  </div>
);
//costdriverchartdata
