import axios from "axios";

export class ProductService {
    getIcisForecastSummaryTable() {
        return fetch("/ui_inputs/icis_forecast_summary_table.json")
            .then((res) => res.json())
            .then((d) => d.Sheet1);
    }

    getMaterialInfo() {
        return fetch("/ui_inputs/overview.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getOptimizationData(params) {
        const options = {
            headers: {
                // "Content-Type": "multipart/form-data; boundary=something",
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "https://expertsinprocurement.com/",
            },
            data: { param1: params.param1, param2: params.param2 },
        };
        return axios
            .post("https://expertsinprocurement.com/SOS/api", options)
            .then(function({ data }) {
                console.log('data',data)
                return data;
            })
            .catch(function(error) {
                console.log('error',error);
            });
    }

    getMaterial() {
        return fetch("/ui_inputs/material_info.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getInventoryInfo() {
        return fetch("/ui_inputs/inventory_info.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getIcisForecastSummaryTable2() {
        return fetch("/ui_inputs/icis_forecast_summary_table_2.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getdemandRegressionSummaryTable2() {
        return fetch("/ui_inputs/demand_info_regression_summary.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    gettransportData() {
        return fetch("/ui_inputs/transportdata.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getPricePridectionTable() {
        return fetch("/ui_inputs/price_pridection_table.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getPlantinventoryTable() {
        return fetch("/ui_inputs/plantinventory.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }

    getPlantInfo() {
        return fetch("/ui_inputs/demandplantinfo.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }
    getDateMaker() {
        return fetch("/ui_inputs/map_fcst_months.json")
            .then((res) => res.json())
            .then((d) => d.data);
    }
}