import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppTopbar } from "./components/layouts/AppTopbar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { MaterialOverview } from "./components/features/MaterialOverview";
import { Materialdatachart } from "./components/features/Materialdatachart";
import { CostDriversAnalysis } from "./components/features/CostDriversAnalysis";
import { CostDriverAnalysisHome } from "./components/pages/home/CostDriverAnalysisHome";
import { LoginPage } from "./components/pages/login/LoginPage";
import { Orderingplant } from "./components/features/Orderingplant";
import { Inventory } from "./components/features/Inventory";
import { SupplierAnalysis } from "./components/features/SupplierAnalysis";
import DemandPrediction from "./components/pages/home/DemandPrediction";
import Home from "./components/pages/home/Home";

const App = (props) => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const isLogin = localStorage.getItem("isLogin");
    if (isLogin) {
      setLogin(true);
    }
  }, []);
  useEffect(() => {
    if (!login) {
      return <Redirect to="/login" />;
    }
  }, [login]);

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const onToggleMenu = (event) => {
    if (isDesktop()) {
      if (layoutMode === "overlay") {
        setOverlayMenuActive((prevState) => !prevState);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }
    event.preventDefault();
  };

  return (
    <Switch>
      <Route path="/" exact render={(props) => <Home login={login} onToggleMenu={onToggleMenu} {...props} />} />
      <Route path="/login" exact render={(props) => <LoginPage setLogin={setLogin} {...props} />} />
      <div>
        <Route path="/" render={(props) => <AppTopbar onToggleMenu={onToggleMenu} setLogin {...props} />} />
        <Route path="/orderOptimization/MaterialOverview" exact render={(props) => <MaterialOverview {...props} />} />
        <Route path="/orderOptimization/Materialdatachart" exact render={(props) => <Materialdatachart {...props} />} />
        <Route
          path="/orderOptimization/CostDriversAnalysis"
          exact
          render={(props) => <CostDriversAnalysis {...props} />}
        />
        <Route path="/orderOptimization/Orderingplant" exact render={(props) => <Orderingplant {...props} />} />
        <Route path="/orderOptimization/Inventory" exact render={(props) => <Inventory {...props} />} />
        <Route path="/orderOptimization/SupplierAnalysis" exact render={(props) => <SupplierAnalysis {...props} />} />
        <Route path="/demandPrediction" exact render={(props) => <DemandPrediction {...props} />} />
        <Route path="/costDriversAnalysis" exact render={(props) => <CostDriverAnalysisHome {...props} />} />
      </div>
    </Switch>
  );
};

export default App;
