import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../../services/ProductService";
import { Button } from "primereact/button";
import "../../components/App.css";
import { Link } from "react-router-dom";
import { headerMaterial, headerOrderingSchedule } from "../../appConstant";
import { ProgressSpinner } from "primereact/progressspinner";
export const Orderingplant = () => {
  const [products, setProducts] = useState([]);
  const [products3, setProducts3] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableHeaders, setTableHeaders] = useState([]);
  const isMounted = useRef(false);
  const productService = new ProductService();
  const [supplierData, setsupplierData] = useState(null);
  const [plantData, setplantData] = useState(null);
  const [details, setDetails] = useState(null);
  const [isOptimalSolution, setIsOptimalSolution] = useState(null);
  let currenYyear = new Date().getFullYear() * 1;
  let currenMonth = new Date().getMonth() * 1;
  const forcastData = JSON.parse(localStorage.getItem("suppliers"));
  const param1 = localStorage.getItem("params1") || [];
  const param2 = localStorage.getItem("params2") || [];
  const dateMaker = (yr, mnt) => {
    const date = new Date(yr, mnt).toLocaleDateString("en-US", {
      month: "short",
      year: "2-digit",
    });
    return date;
  };
  useEffect(() => {
    isMounted.current = true;
    productService.getMaterial().then((data) => {
      let materilaData = data.Sheet3.filter((data) => data.material === localStorage.getItem("Material"));
      setProducts3(materilaData);
    });
  }, []);
  useEffect(() => {
    isMounted.current = true;
    productService.getMaterialInfo().then((data) => setProducts(data));
    setTimeout(()=>{
      productService.getOptimizationData({ param1, param2 }).then((data) => {
        // console.log('param1====>',param1)
        const supplierDataRes = data?.data?.[0][0];
        const plantDataRes = data?.data?.[0][1];
        const details = data?.data?.[0][2]?.details;
        const optimalSolution = data?.data?.[0][2]?.optimalSolution;
        setIsLoading(false);
        setsupplierData(supplierDataRes);
        setplantData(plantDataRes);
        setDetails(details);
        setIsOptimalSolution(optimalSolution);
      });
    },10000)
    

    // const orderingSchedule1 = supplierJsonData.Sheet1.map((item) => {

    //   return {
    //     Suppliers: item.Supplier ,
    //     May: (item.May * 1).toFixed(2),
    //     June: (item.June * 1).toFixed(2),
    //     July: (item.July * 1).toFixed(2),
    //     August: (item.August * 1).toFixed(2),
    //     September: (item.September * 1).toFixed(2),
    //     October: (item.October * 1).toFixed(2),
    //     Total_Quantity: (item?.['Total Quantity'] * 1).toFixed(2),
    //     Plants: item.Plant ,
    //     // ...item, Plant: localStorage.getItem("plant"), Supplier: forcastData[0].name
    //    };
    // });
    // const orderingSchedule2 = plantJsonData.data.Sheet1.map((item) => {

    //   return {
    //     category: item.category ,
    //     May: (item.May * 1).toFixed(2),
    //     June: (item.June * 1).toFixed(2),
    //     July: (item.July * 1).toFixed(2),
    //     August: (item.August * 1).toFixed(2),
    //     September: (item.September * 1).toFixed(2),
    //     October: (item.October * 1).toFixed(2),
    //     Plants: item.Plant ,

    //     // ...item, Plant: localStorage.getItem("plant"), Supplier: forcastData[0].name

    //   };
    // });

    // setsupplierData(orderingSchedule1);
    // setplantData(orderingSchedule2);
  }, []);
  useEffect(()=>{
    const Datepicker = JSON.parse(localStorage.getItem('DateMaker'))
    setTableHeaders(Datepicker)
    },[])

  return (
    <div>
      <div className="layout-main">
        <h5
          style={{
            fontWeight: "bolder",
            fontFamily: "poppins",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          Ordering Schedule
        </h5>

        <div className="card">
          <DataTable value={products3} responsiveLayout="scroll" dataKey="id" header={headerMaterial} rows={1}>
            <Column field="material" header="ID"></Column>
            <Column field="material_type (SAP)" header="Type" />
            <Column field="material_description_1" header="Description"></Column>
            <Column field="base_unit_of_measure (UOM)" header="UOM"></Column>
            <Column field="mdrm_class (class)" header="UNSPSC Description" />
          </DataTable>
        </div>
        <>
         </>
        { isOptimalSolution == true ? (
        <>   
          <div className="card">
            <DataTable
              value={supplierData}
              responsiveLayout="scroll"
              dataKey="id"
              header={headerOrderingSchedule}
              rows={1}
              //loading={isLoading}
            >
              <Column field="Plant" header="Plant"></Column>
              <Column field="Supplier" header="Supplier Name"></Column>
              <Column field="Month1" header={tableHeaders[0]?.month_1||''} />
              <Column field="Month1"  header={tableHeaders[1]?.month_2||''} />
              <Column field="Month1" header={tableHeaders[2]?.month_3||''}/>
              <Column field="Month1" header={tableHeaders[3]?.month_4||''} />
              <Column field="Month1" header={tableHeaders[4]?.month_5||''} />
              <Column field="Month1"  header={tableHeaders[5]?.month_6||''} />
              
              {/* <Column field="Month1" header={dateMaker(currenYyear, currenMonth)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 1)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 2)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 3)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 4)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 5)} /> */}
              <Column field="Total Quantity" header="Total Quantities" />
            </DataTable>
          </div>
          <div className="card">
            <DataTable
              value={plantData}
              dataKey="id"
              showGridlines
              responsiveLayout="scroll"
              rows={6}
              //loading={isLoading}
            >
              <Column field="Plant" header="Plant"></Column>
              <Column field="category" header="Name"></Column>
              <Column field="Month1" header={tableHeaders[0]?.month_1||''} />
              <Column field="Month1"  header={tableHeaders[1]?.month_2||''} />
              <Column field="Month1" header={tableHeaders[2]?.month_3||''}/>
              <Column field="Month1" header={tableHeaders[3]?.month_4||''} />
              <Column field="Month1" header={tableHeaders[4]?.month_5||''} />
              <Column field="Month1"  header={tableHeaders[5]?.month_6||''} />
              {/* <Column field="Month1" header={dateMaker(currenYyear, currenMonth)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 1)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 2)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 3)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 4)} />
              <Column field="Month1" header={dateMaker(currenYyear, currenMonth + 5)} /> */}
            </DataTable>
          </div>
        
        </>
      ) : (
          <>
            <div
              className="card"
              style={{
                display: "flex",
                flexDirection:'column',
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "red",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
             { details?.map((err)=> 
             <table className="table">
             <tr>
            <td>{err}</td>
            </tr>
             </table>)}
            </div>
          </>
        )} 



        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/orderOptimization/Inventory">
            <Button className="previousbutton" label="Previous " style={{ marginRight: " 15px" }} />
          </Link>

          <Button
            className="nextbutton"
            label="Download Ordering schedule "
            style={{ marginLeft: " 15px" }}
            icon="pi pi-lock"
          />
        </div>
      </div>
      
    </div>
  );
};
