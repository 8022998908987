import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "../../services/ProductService";
import { Button } from "primereact/button";
import "../../components/App.css";
import { Link } from "react-router-dom";
import { headerMaterial, headerInventory, headerForecastedPrices } from "../../appConstant";

export const Inventory = (props) => {
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const isMounted = useRef(false);
  const productService = new ProductService();
  const [plantData2000, setplantData2000] = useState([]);
  const [supplierObject, setsupplierObject] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  let currenYyear = new Date().getFullYear() * 1;
  let currenMonth = new Date().getMonth() * 1;

  useEffect(() => {
    isMounted.current = true;
    productService.getMaterial().then((data) => {
      let materilaData = data.Sheet3.filter((data) => data.material === localStorage.getItem("Material"));
      setProducts(materilaData);
    });
    const forcastData = JSON.parse(localStorage.getItem("supplierObject"));
    setsupplierObject(forcastData);
    window.supplierObject = forcastData;
  }, []);

  useEffect(() => {
    isMounted.current = true;
    productService.getInventoryInfo().then((data) => {
      let materilaData = data.Sheet3.filter(
        (data) => data.material === localStorage.getItem("Material") && data.plant === localStorage.getItem("plant")
      );
      setProducts2(materilaData);
    });
  }, []);

  useEffect(() => {
    isMounted.current = true;
    productService.getPlantinventoryTable().then((data) => setPlantData(data));
    productService.getPlantinventoryTable().then((data) => {
      let modifieData = data.Sheet1.map((ele) => {
        return {
          Data: ele.data,
          plant: ele.plant,
          material_number: ele.material_number,
          key_mp: ele.key_mp,
          Month1: (ele.month_1 * 1).toFixed(2),
          Month2: (ele.month_2 * 1).toFixed(2),
          Month3: (ele.month_3 * 1).toFixed(2),
          Month4: (ele.month_4 * 1).toFixed(2),
          Month5: (ele.month_5 * 1).toFixed(2),
          Month6: (ele.month_6 * 1).toFixed(2),
        };
      });
      setplantData2000(
        modifieData.filter(
          (data) =>
            data.plant === localStorage.getItem("plant") && data.material_number === localStorage.getItem("Material")
        )
      );
    });
  }, []);

  const dateMaker = (yr, mnt) => {
    const date = new Date(yr, mnt).toLocaleDateString("en-US", {
      month: "short",
      year: "2-digit",
    });
    return date;
  };

  const statusOrderBodyTemplate = (rowData) => {
    return (
      <span className={`productss-badge status-${rowData.status_level_material.toLowerCase()}`}>
        {rowData.status_level_inventory}{" "}
      </span>
    );
  };

  const parameterMaker = () => {
    const { warehouse_capacity, safety_stock, plant } = products2.length > 0 && products2[0];

    let params1 = [];
    let params2 = [];
    let count = 1;

    // eslint-disable-next-line no-unused-vars
    if (products2.length > 0 && plantData2000.length > 0) {
      for (const ele in supplierObject[0]) {
        const month = `month${count}`;
        const Month = `Month${count}`;
        if (count < 7) {
          params1 = [
            ...params1,
            {
              opening_stock: plantData2000[0][Month] * 1,
              on_route: plantData2000[1][Month] * 1,
              predicted_consumption_mp_level: plantData2000[2][Month] * 1,
              Period: Month,
              warehouse_capacity: warehouse_capacity * 1,
              plant: plant * 1,
              safety_stock: safety_stock * 1,
            },
          ];

          params2 = [
            ...params2,
            {
              Period: Month,
              Supplier: supplierObject[0]["name"],
              Price: supplierObject[0][month],
              MaxSupply: supplierObject[0]["MaxSupply"],
              Lead_time: supplierObject[0]["Lead_time"],
            },
          ];
        }
        count++;
      }
    }
    return { params1, params2 };
  };

  setTimeout(() => {
    let res = parameterMaker();
    localStorage.setItem("params1", JSON.stringify(res?.params1));
    localStorage.setItem("params2", JSON.stringify(res?.params2));
  }, 5000);

  const headerPlant = (
    <div className="table-header-container">
      <h5 style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>Plant- {localStorage.getItem("plant")}</h5>
      <h6 style={{ fontWeight: "lighter", fontFamily: "Poppins" }}>All values are in Tonnes</h6>
    </div>
  );
  useEffect(()=>{
    const Datepicker = JSON.parse(localStorage.getItem('DateMaker'))
    setTableHeaders(Datepicker)
    },[])

  return (
    <div>
      <div className="layout-main">
        <h5
          style={{
            fontWeight: "bolder",
            fontFamily: "poppins",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          Data Overview
        </h5>
        <div className="card">
          <DataTable value={products} responsiveLayout="scroll" dataKey="id" header={headerMaterial} rows={1}>
            <Column field="material" header="ID"></Column>
            <Column field="base_unit_of_measure (UOM)" header="UOM"></Column>
            <Column field="aliases" header="Aliases" />
            <Column field="material_type (SAP)" header="SAP" />
            <Column field="material_group (organisation)" header="Organization" />
            <Column field="mdrm_class (class)" header="Class" />
          </DataTable>
        </div>
        <div className="card">
          <DataTable value={products2} dataKey="id" header={headerInventory} rows={4} responsiveLayout="scroll">
            <Column field="plant" header="Plant ID"></Column>
            <Column field="plant_name" header="Plant Name"></Column>
            <Column field="safety_stock" header="Safety Stock"></Column>
            <Column field="opening_stock" header="Inventory" />
            <Column field="warehouse_capacity" header="Warehouse capacity" />
            <Column field="status_level_inventory" header="Status" body={statusOrderBodyTemplate} />
          </DataTable>
        </div>
        <div className="card">
          <DataTable value={plantData2000} dataKey="plant" header={headerPlant} rows={4} responsiveLayout="scroll">
            <Column field="Data" header=""></Column>
            <Column field="Month1" header={tableHeaders[0]?.month_1||''}/>
            <Column field="Month2" header={tableHeaders[1]?.month_2||''} />
            <Column field="Month3" header={tableHeaders[2]?.month_3||''}/>
            <Column field="Month4" header={tableHeaders[3]?.month_4||''}  />
            <Column field="Month5"  header={tableHeaders[4]?.month_5||''}/>
            <Column field="Month6" header={tableHeaders[5]?.month_6||''}/>
            
            {/* <Column field="Month1" header={dateMaker(currenYyear, currenMonth)} />
            <Column field="Month2" header={dateMaker(currenYyear, currenMonth + 1)} />
            <Column field="Month3" header={dateMaker(currenYyear, currenMonth + 2)} />
            <Column field="Month4" header={dateMaker(currenYyear, currenMonth + 3)} />
            <Column field="Month5" header={dateMaker(currenYyear, currenMonth + 4)} />
            <Column field="Month6" header={dateMaker(currenYyear, currenMonth + 5)} /> */}
          </DataTable>
        </div>
        <div className="card">
          <DataTable
            value={supplierObject}
            dataKey="id"
            header={headerForecastedPrices}
            rows={3}
            responsiveLayout="scroll"
          >
            <Column field="name" header="Supplier"></Column>
            <Column field="month1"   header={tableHeaders[0]?.month_1||''} />
            <Column field="month2"  header={tableHeaders[1]?.month_2||''} />
            <Column field="month3"  header={tableHeaders[2]?.month_3||''} />
            <Column field="month4"  header={tableHeaders[3]?.month_4||''}  />
            <Column field="month5"   header={tableHeaders[4]?.month_5||''} />
            <Column field="month6"  header={tableHeaders[5]?.month_6||''}  />

            {/* <Column field="month1" header={dateMaker(currenYyear, currenMonth)} />
            <Column field="month2" header={dateMaker(currenYyear, currenMonth + 1)} />
            <Column field="month3" header={dateMaker(currenYyear, currenMonth + 2)} />
            <Column field="month4" header={dateMaker(currenYyear, currenMonth + 3)} />
            <Column field="month5" header={dateMaker(currenYyear, currenMonth + 4)} />
            <Column field="month6" header={dateMaker(currenYyear, currenMonth + 5)} /> */}
          </DataTable>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link to="/orderOptimization/SupplierAnalysis">
            <Button className="previousbutton" label="Previous" style={{}} />
          </Link>
          <Link to="/orderOptimization/Orderingplant">
            <Button className="nextbutton" label="Generate ordering schedule" style={{ marginLeft: " 15px" }} />
          </Link>
        </div>
      </div>
    </div>
  );
};
