import axios from "axios";

export default class ProcService {
  getMaterialInfo(params) {
    return axios
      .get("/ui_inputs/material_info.json", { params: params })
      .then((res) => res)
      .catch((e) => console.log(`error in getMaterialInfo ${e.message}`));
  }

  getIcisForecastSummaryTable2(params) {
    return axios
      .get("/ui_inputs/icis_forecast_summary_table_2.json", {
        params: params,
      })
      .then((res) => res)
      .catch((e) => console.log(`error in icis_forecast_summary_table_2 ${e.message}`));
  }

  getCategoryTable(params) {
    return axios
      .get("/ui_inputs/inventory_info new.json", {
        params: params,
      })
      .then((res) => res)
      .catch((e) => console.log(`error in getDemandUITable ${e.message}`));
  }

  getHistoricalUnitPrice(params) {
    return axios
      .get("/ui_inputs/history_unit_price_info.json", {
        params: params,
      })
      .then((res) => res)
      .catch((e) => console.log(`error in getHistoricalUnitPrice ${e.message}`));
  }

  getInventoryInfo(params) {
    return axios
      .get("/ui_inputs/inventory_info.json", { params: params })
      .then((res) => res)
      .catch((e) => console.log(`error in getInventoryInfo ${e.message}`));
  }

  getMaterialCostDriverOutput() {
    return axios
      .get("/ui_inputs/material_cost_driver_output.json")
      .then((res) => res)
      .catch((e) => console.log(`error in getMaterialCostDriverOutput ${e.message}`));
  }

  getPlants(params) {
    return axios
      .get("/ui_inputs/on_route_info.json", { params: params })
      .then((res) => {
        const unique = [...new Set(res.data.Sheet2.map((item) => item.plant))];
        return unique;
      })
      .catch((e) => console.log(`error in getDemandInfoRegression1 ${e.message}`));
  }

  getDemandInfoRegressionSummaryTable(params) {
    return axios
      .get("/ui_inputs/demand_info_regression_summary.json", {
        params: params,
      })
      .then((res) => res)
      .catch((e) => console.log(`error in getDemandInfoRegressionSummaryTable ${e.message}`));
  }

  getIcisForecastSummaryTable(params) {
    return axios
      .get("/ui_inputs/icis_forecast_summary_table.json", { params: params })
      .then((res) => res)
      .catch((e) => console.log(`error in getIcisForecastSummaryTable ${e.message}`));
  }
}
